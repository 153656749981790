import React, { useEffect , useState } from "react";
import PropTypes from "prop-types";
import SelectLocation from "../dropdown";
import axios from 'axios';
import BASE_URL from "../../apiconfig";


FormSearch.propTypes = {};

function FormSearch(props) {

  // code start here

  const [categories, setCategories] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProfile, setSelectedProfile] = useState(''); // Track selected profile

  useEffect(() => {
    axios.get(`${BASE_URL}/user/dashboard/getJobCategories`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios.get(`${BASE_URL}/user/dashboard/getProfilesByCategory/${selectedCategory}`)
        .then(response => {
          console.log('Profiles data:', response.data); // Log the response to check data
          setProfiles(response.data);
        })
        .catch(error => console.error('Error fetching profiles:', error));
    }
  }, [selectedCategory]);


  // useEffect(() => {
  //   if (selectedCategory) {
  //     axios.get(`https://localhost/professionalplacementdesk/user/dashboard/getProfilesByCategory/${selectedCategory}`)
  //       .then(response => setProfiles(response.data))
  //       .catch(error => console.error('Error fetching profiles:', error));
  //   }
  // }, [selectedCategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `/job-grid?profile_id=${selectedProfile}`;
    console.log('Redirecting to:', url); // Check the generated URL
    window.location.href = url;
  };
  
  // code ends here

  // const [categories, setCategories] = useState([]);
  // const [profiles, setProfiles] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState('');

  // // Fetch categories on component mount
  // useEffect(() => {
  //   axios.get('https://localhost/professionalplacementdesk/user/dashboard/getJobCategories')
  //     .then(response => {
  //       setCategories(response.data);
  //     })
  //     .catch(error => console.error('Error fetching categories:', error));
  // }, []);

  // // Fetch profiles when a category is selected
  // useEffect(() => {
  //   if (selectedCategory) {
  //     axios
  //       .get(
  //         `https://localhost/professionalplacementdesk/user/dashboard/getProfilesByCategory/${selectedCategory}`
  //       )
  //       .then((response) => {
  //         setProfiles(response.data);
  //       })
  //       .catch((error) => console.error("Error fetching profiles:", error));
  //   }
  // }, [selectedCategory]);
  // const [isOpen, setIsOpen] = useState(null);

  // const handleClick = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <section className="form-sticky stc1">
      <div className="tf-container">
        <div className="job-search-form inner-form-map st1">
          {/* code starts here */}
          <form onSubmit={handleSubmit}>
                  <div className="row-group-search home1 st">
                    <div className="form-group-1">
                      <select
                        className="input-filter-search"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Select Job Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.catname}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group-1">
                      <select
                        className="input-filter-search"
                        value={selectedProfile}
                        onChange={(e) => setSelectedProfile(e.target.value)}
                      >
                        <option value="">Select Job Profile</option>
                        {profiles.map((profile) => (
                          <option key={profile.id} value={profile.id}>
                            {profile.pname} {/* Display profile name */}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group-4">
                      <button type="submit" className="btn btn-find">
                        Find Jobs
                      </button>
                    </div>
                  </div>
                </form>

          {/* code ends here */}
          {/* <form action="/job-grid">
            <div className="row-group-search home1 st">
              <div className="form-group-1">
                <select
                  className="input-filter-search "
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Select Job Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.catname}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group-1">
                <select className="input-filter-search">
                  <option value="">Select Job Profile</option>
                  {profiles.map((profile) => (
                    <option key={profile.pname} value={profile.pname}>
                      {profile.pname}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group-4">
                <button type="submit" className="btn btn-find">
                  Find Jobs
                </button>
              </div>
            </div>
          </form> */}
        </div>
      </div>
    </section>
  );
}

export default FormSearch;
