import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../components/breadcrumb";
import EmpSec3 from "../components/employer/EmpSec3";
import dataEm from "../assets/fakeData/dataEmployers";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState , useEffect } from "react";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import Header2 from "../components/header/Header2";
import Mobile from "../components/mobile";
import BASE_URL from "../apiconfig";
Employer_v3.propTypes = {};

function Employer_v3(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  // my code goes here
  // const [dataEm, setDataEm] = useState([]);

  // useEffect(() => {
  //   fetch('https://localhost/professionalplacementdesk/user/dashboard/getcompanies')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data); // Add this line to inspect the data
  //       setDataEm(data);
  //     })
  //     .catch((error) => console.error('Error fetching company data:', error));
  // }, []);


  const [dataEm, setDataEm] = useState([]); // State to hold the company data

  // Fetch data from the backend when the component mounts
  useEffect(() => {
    fetch(`${BASE_URL}/user/dashboard/getcompanies`)
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        setDataEm(data); // Set the data in the state
      })
      .catch((error) => console.error('Error fetching companies:', error));
  }, []);


  // your code end here

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">

              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item menu-item-has-children-mobile ">
                        <Link to="/">Home</Link>
                      </li> */}

                      {/* <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/aboutus">About us</Link>
                      </li> */}
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/job-grid">Browse jobs </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/employers_v3">Companies </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/faqs">Services </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

            </div>
          </Tabs>

          <div className="header-customize-item button">
            {/* <Link to="/">Upload Resume</Link> */}
            <Mobile />
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:9811226910">9811226910</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header2 clname="actEm5" handleMobile={handleMobile} />
      <Breadcrumb title="Employers" className="breadcrumb-section" />
      <EmpSec3 data={dataEm} />
      <Footer />
      <Gotop />
    </>
  );
}

export default Employer_v3;
