// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// BlogSec2.propTypes = {
    
// };

// function BlogSec2(props) {
//     const {data} = props
//     return (
//         <section className="blog-section">
//     <div className="tf-container">
//       <div className="group-col-3">
//         {
//             data.slice(3,12).map(idx => (
//                 <div key={idx.id} className="widget-blog-1 style-1 cl3 stc">
//                     <div className="img-blog">
//                     <img src={idx.img} alt="image" />
//                     </div>
//                     <div className="content">
//                     <span className="sub-title">{idx.cate}</span>
//                     <h3 className="main-title"><Link to="/blogsingle_v1">{idx.title}</Link></h3>
//                     <ul className="meta">
//                         <li className="author"><span>by</span>{idx.author}</li>
//                         <li className="time"><span className="icon-calendar"></span> {idx.time}</li>
//                     </ul>
//                     </div>
//                 </div>
//             ))
//         }
       

//       </div>
//       <ul className="pagination-job">
//         <li><Link to="#"><i className="icon-keyboard_arrow_left"></i></Link></li>
//         <li><Link to="#">1</Link></li>
//         <li className="current"><Link to="#">2</Link></li>
//         <li><Link to="#">3</Link></li>
//         <li><Link to="#"><i className="icon-keyboard_arrow_right"></i></Link></li>
//       </ul>
//     </div>

//   </section>
//     );
// }

// export default BlogSec2;



// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

// function BlogSec2() {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         // Fetch blog data from the CodeIgniter backend
//         fetch('https://localhost/professionalplacementdesk/user/dashboard/getblogs') // Replace with your actual URL
//             .then(response => response.json())
//             .then(data => {
//                 setData(data); // Set the fetched data into state
//             })
//             .catch(error => console.error('Error fetching blog data:', error));
//     }, []);

//     return (
//         <section className="blog-section">
//             <div className="tf-container">
//                 <div className="group-col-3">
//                     {data.slice(3, 12).map(idx => (
//                         <div key={idx.id} className="widget-blog-1 style-1 cl3 stc">
//                             <div className="img-blog">
//                                 <img src={`https://localhost/professionalplacementdesk/uploads/${idx.image}`} alt="image" />
//                             </div>
//                             <div className="content">
//                                 <span className="sub-title">{idx.cate}</span>
//                                 <h3 className="main-title">
//                                     <Link to="/blogsingle_v1">{idx.title}</Link>
//                                 </h3>
//                                 <ul className="meta">
//                                     <li className="author"><span>by</span>{idx.author}</li>
//                                     <li className="time"><span className="icon-calendar"></span> {idx.date}</li>
//                                 </ul>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//                 <ul className="pagination-job">
//                     <li><Link to="#"><i className="icon-keyboard_arrow_left"></i></Link></li>
//                     <li><Link to="#">1</Link></li>
//                     <li className="current"><Link to="#">2</Link></li>
//                     <li><Link to="#">3</Link></li>
//                     <li><Link to="#"><i className="icon-keyboard_arrow_right"></i></Link></li>
//                 </ul>
//             </div>
//         </section>
//     );
// }

// export default BlogSec2;



import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BASE_URL from '../../apiconfig';

// function BlogSec2() {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         // Fetch blog data from the CodeIgniter backend
//         fetch('https://localhost/professionalplacementdesk/user/dashboard/getblogs') // Replace with your actual URL
//             .then(response => response.json())
//             .then(data => {
//                 setData(data); // Set the fetched data into state
//             })
//             .catch(error => console.error('Error fetching blog data:', error));
//     }, []);

//     return (
//         <section className="blog-section">
//             <div className="tf-container">
//                 <div className="group-col-3">
//                     {data.map(idx => (  // Removed slice to display all blogs
//                         <div key={idx.id} className="widget-blog-1 style-1 cl3 stc">
//                             <a href="/blogsingle_v1" className="img-blog">
//                                 <img src={`https://localhost/professionalplacementdesk/uploads/${idx.image}`} alt="image" />

//                             </a>
//                             <div className="content">
//                                 <span className="sub-title">{idx.cate}</span>
//                                 <h3 className="main-title">
//                                     <Link to="/blogsingle_v1">{idx.title}</Link>
//                                 </h3>
//                                 <ul className="meta">
//                                     <li className="author"><span>by</span>{idx.author}</li>
//                                     <li className="time"><span className="icon-calendar"></span> {idx.date}</li>
//                                 </ul>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//                 <ul className="pagination-job">
//                     <li><Link to="#"><i className="icon-keyboard_arrow_left"></i></Link></li>
//                     <li><Link to="#">1</Link></li>
//                     <li className="current"><Link to="#">2</Link></li>
//                     <li><Link to="#">3</Link></li>
//                     <li><Link to="#"><i className="icon-keyboard_arrow_right"></i></Link></li>
//                 </ul>
//             </div>
//         </section>
//     );
// }

function BlogSec2() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`${BASE_URL}/user/dashboard/getblogs`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            })
            .catch(error => console.error('Error fetching blog data:', error));
    }, []);

    return (
        <section className="blog-section">
            <div className="tf-container">
                <div className="group-col-3">
                    {data.map(idx => (
                        <div key={idx.id} className="widget-blog-1 style-1 cl3 stc">
                            <Link to={`/blogsingle_v1/${idx.id}`} className="img-blog">
                                <img src={`${BASE_URL}/uploads/${idx.image}`} alt="image" />
                            </Link>
                            <div className="content">
                                {/* <span className="sub-title">{idx.cate}</span> */}
                                <h3 className="main-title">
                                    <Link to={`/blogsingle_v1/${idx.id}`}>{idx.title}</Link>
                                </h3>
                                <ul className="meta">
                                    {/* <li className="author"><span>by</span>{idx.author}</li> */}
                                    <li className="time"><span className="icon-calendar"></span> {idx.date}</li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default BlogSec2;
