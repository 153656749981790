import React, { useEffect , useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SelectLocation from "../dropdown";
import CircleProgess from "../progressBar";
import axios from 'axios';
import BASE_URL from "../../apiconfig";

Banner04.propTypes = {};

function Banner04(props) {

  // code start here

  const [categories, setCategories] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProfile, setSelectedProfile] = useState(''); // Track selected profile

  useEffect(() => {
    axios.get(`${BASE_URL}/user/dashboard/getJobCategories`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios.get(`${BASE_URL}/user/dashboard/getProfilesByCategory/${selectedCategory}`)
        .then(response => {
          console.log('Profiles data:', response.data); // Log the response to check data
          setProfiles(response.data);
        })
        .catch(error => console.error('Error fetching profiles:', error));
    }
  }, [selectedCategory]);

  // useEffect(() => {
  //   if (selectedCategory) {
  //     axios.get(`https://localhost/professionalplacementdesk/user/dashboard/getProfilesByCategory/${selectedCategory}`)
  //       .then(response => setProfiles(response.data))
  //       .catch(error => console.error('Error fetching profiles:', error));
  //   }
  // }, [selectedCategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `/job-grid?profile_id=${selectedProfile}`;
    console.log('Redirecting to:', url); // Check the generated URL
    window.location.href = url;
  };
  

  // code ends here

  // const [categories, setCategories] = useState([]);
  // const [profiles, setProfiles] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState('');

  // // Fetch categories on component mount
  // useEffect(() => {
  //   axios.get('https://localhost/professionalplacementdesk/user/dashboard/getJobCategories')
  //     .then(response => {
  //       setCategories(response.data);
  //     })
  //     .catch(error => console.error('Error fetching categories:', error));
  // }, []);

  // // Fetch profiles when a category is selected
  // useEffect(() => {
  //   if (selectedCategory) {
  //     axios.get(`https://localhost/professionalplacementdesk/user/dashboard/getProfilesByCategory/${selectedCategory}`)
  //       .then(response => {
  //         setProfiles(response.data);
  //       })
  //       .catch(error => console.error('Error fetching profiles:', error));
  //   }
  // }, [selectedCategory]);

  return (
    <section className="tf-slider sl3 over-flow-hidden">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="content wow fadeInUp">
              <div className="heading">
                <h2 className="text-white">Your dream job in one place</h2>
                <p className="text-white">
                  Professional Placement Desk is a global online employment
                  solution for people seeking jobs and the employers who need
                  great people in their field.
                </p>
              </div>
              <div className="form-sl">
                {/* <form action="/job-grid">
                  <div className="row-group-search home1 st">
                    <div className="form-group-1"> */}
                {/* <span className="icon-search search-job"></span> */}
                {/* <select
                        className="input-filter-search"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Select Job Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.catname}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group-1">
                      <select className="input-filter-search">
                        <option value="">Select Job Profile</option>
                        {profiles.map((profile) => (
                          <option key={profile.pname} value={profile.pname}>
                            {profile.pname}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group-4">
                      <button type="submit" className="btn btn-find">
                        Find Jobs
                      </button>
                    </div>
                  </div>
                </form> */}

                {/* code start here */}
                <form onSubmit={handleSubmit}>
                  <div className="row-group-search home1 st">
                    <div className="form-group-1">
                      <select
                        className="input-filter-search"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Select Job Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.catname}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group-1">
                      <select
                        className="input-filter-search"
                        value={selectedProfile}
                        onChange={(e) => setSelectedProfile(e.target.value)}
                      >
                        <option value="">Select Job Profile</option>
                        {profiles.map((profile) => (
                          <option key={profile.id} value={profile.id}>
                            {profile.pname} {/* Display profile name */}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group-4">
                      <button type="submit" className="btn btn-find">
                        Find Jobs
                      </button>
                    </div>
                  </div>
                </form>

                {/* code ends here */}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="wd-review-job thumb2 widget-counter tf-sl3">
              <div className="thumb">
                <div className="box1">
                  <img
                    src={require("../../assets/images/review/thumb5c.jpeg")}
                    alt="images"
                  />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape3.png")}
                      alt="images"
                    />
                  </div>
                </div>
                <div className="box2">
                  <img
                    src={require("../../assets/images/review/thumb6c.jpg")}
                    alt="images"
                  />
                  <div className="markk ani3">
                    <img
                      src={require("../../assets/images/review/shape4.png")}
                      alt="images"
                    />
                  </div>
                </div>
              </div>
              <div className="tes-box ani5">
                <div className="client-box">
                  <div className="avt">
                    <img
                      src={require("../../assets/images/review/face-1298202_1280.png")}
                      alt="images"
                    />
                    <div className="badge"></div>
                  </div>
                  <div className="content">
                    <h6 className="number wrap-counter">200+ recruiters</h6>
                    <div className="des">Happy Clients</div>
                  </div>
                </div>
              </div>
              <div className="icon1 ani3">
                <img
                  src={require("../../assets/images/review/icon2.png")}
                  alt="images"
                />
              </div>
              <div className="icon2 ani4">
                <img
                  src={require("../../assets/images/review/icon3.png")}
                  alt="images"
                />
              </div>
              <div className="icon3 ani6">
                <img
                  src={require("../../assets/images/review/icon11.png")}
                  alt="images"
                />
              </div>

              <div className="chart-box counter">
                <h6>Candidates</h6>
                <CircleProgess />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner04;
