import React from "react";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
// import PropTypes from "prop-types";
import dataBlog from "../assets/fakeData/dataBlog";
import Blog01 from "../components/blog/Blog01";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MapSingle from "../components/map/MapSingle";
import lo1 from "../assets/images/logo-company/cty4.png";
import StarProgress from "../components/progressBar/StarProgress";
import { useRef, useState, useEffect } from "react";
import Video from "../components/popup/Video";
import Gallery from "../components/popup/Gallery";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import Header2 from "../components/header/Header2";
import "./jobsingle_v1.css";
import Mobile from "../components/mobile";
import BASE_URL from "../apiconfig";


Jobsingle_v1.propTypes = {};

const marKers = [
  {
    id: 1,
    title: "Rockstar Games New York",
    name: "Senior UI/UX Designer",
    address: "Las Vegas, NV 89107, USA",
    longitude: -74.00122,
    latitude: 40.71023,
    img: lo1,
  },
];

function Jobsingle_v1(props) {

  
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  const { jobId } = useParams();  // Capture jobId from URL
  const navigate = useNavigate();  // Use useNavigate for navigation
  const [jobDetails, setJobDetails] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  
  // Get email of the job seeker from localStorage (session or cookie can be used instead)
  const jobSeekerEmail = localStorage.getItem('email');
  
  useEffect(() => {
    fetch(`${BASE_URL}/user/dashboard/getJob/${jobId}`)
      .then((response) => response.json())
      .then((data) => setJobDetails(data))
      .catch((error) => console.error("Error fetching job details:", error));
  }, [jobId]);
  
  const handleApply = () => {
    if (!isLoggedIn) {
      alert("Please log in to apply.");
      return;
    }

    // Fetch job_seeker_id using email
    fetch(`${BASE_URL}/user/dashboard/getJobSeekerIdByEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: jobSeekerEmail }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        const jobSeekerId = data.job_seeker_id;

        // Now send the apply request with job_seeker_id
        fetch(`${BASE_URL}/user/dashboard/applyJob`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            job_seeker_id: jobSeekerId,
            job_posting_id: jobId,
          }),
        })
        .then((response) => response.json())
        .then((applyData) => {
          if (applyData.success) {
            setIsApplied(true);
          } else {
            alert("Failed to apply. Try again.");
          }
        })
        .catch((error) => console.error("Error applying for job:", error));
      } else {
        alert("Failed to retrieve Job Seeker ID.");
      }
    })
    .catch((error) => console.error("Error fetching job seeker ID:", error));
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: isLoggedIn ? '#4CAF50' : '#f44336', // Green if logged in, red if not
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  };

  // const { jobId } = useParams();  // Capture jobId from URL
  // const [jobDetails, setJobDetails] = useState(null);

  // useEffect(() => {
  //   fetch(`https://localhost/professionalplacementdesk/user/dashboard/getJob/${jobId}`)
  //     .then((response) => response.json())
  //     .then((data) => setJobDetails(data))
  //     .catch((error) => console.error("Error fetching job details:", error));
  // }, [jobId]);

  if (!jobDetails) {
    return <div>Loading...</div>;  // Loading state
  }

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">

              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/job-grid">Browse jobs </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/employers_v3">Companies </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/faqs">Services </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            {/* <Link to="/">Upload Resume</Link> */}
            <Mobile />
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:9811226910">9811226910</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header2 clname="actJob2" handleMobile={handleMobile} />

      <section className="job-details-section mt-5 jobdetail">
        <div className="tf-container">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-7 details">
              {jobDetails && (
                <>
                  <div className="company-logo p-2">
                    <img
                      src={`${BASE_URL}/uploads/${jobDetails.image}`}
                      alt={jobDetails.company_name}
                      style={{ width: "100px" }}
                    />
                  </div>
                  <h5 className="p-2">{jobDetails.title}</h5>
                  <h6 className="p-2">{jobDetails.company_name}</h6>
                  <div>
                    <p className="p-2">Location : {jobDetails.location}</p>
                    <p className="p-2">
                      Salary : {jobDetails.salarymin} - {jobDetails.salarymax} /
                      year
                    </p>
                  </div>
                  <h4 className="mt-1 ">Job Description</h4>
                  <p className="p-2 detai-content">{jobDetails.details}</p>

                  {/* <button
                    className="btn btn-popup button"
                    style={{ backgroundColor: isApplied ? "gray" : "#14a077" }}
                    onClick={handleApply}
                    disabled={isApplied}
                  >
                    {isApplied ? "Applied" : "Apply"}
                  </button> */}

                  {isLoggedIn ? (
                    <button
                      className="btn btn-popup button"
                      style={{
                        backgroundColor: isApplied ? "gray" : "#14a077",
                      }}
                      onClick={handleApply}
                      disabled={isApplied}
                    >
                      {isApplied ? "Applied" : "Apply"}
                    </button>
                  ) : (
                    <button
                      className="btn btn-popup button"
                      style={buttonStyle}
                      onClick={() => navigate("/login")} // Redirect to login page
                    >
                      Login to Apply
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="col-lg-4" style={{ padding: "0" }}>
              <div
                className="cv-form-details po-sticky job-sg single-stick"
                style={{
                  background: "#fff",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="mt-4 mb-4">
                  <h4>Job Information</h4>
                </div>
                <ul className="list-infor">
                  {/* <li>
                    <div className="category">Website</div>
                    <div className="detail">
                      <Link to="https://themeforest.net/user/themesflat">
                        Themesflat.vn
                      </Link>
                    </div>
                  </li> */}
                  <li>
                    <div className="category">Date Posted</div>
                    <div className="detail">
                      {/* {new Date(jobDetails.date).toLocaleDateString()} */}
                      {formatDate(jobDetails.date)}
                    </div>
                  </li>
                  <li>
                    <div className="category">Job Location</div>
                    <div className="detail">{jobDetails.location}</div>
                  </li>
                  <li>
                    <div className="category">Experience in years</div>
                    <div className="detail">{jobDetails.mine}</div>
                  </li>
                  <li>
                    <div className="category">Qualification</div>
                    <div className="detail">{jobDetails.quali}</div>
                  </li>
                  <li>
                    <div className="category">Offered Salary</div>
                    <div className="detail">{jobDetails.salarymin}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Blog01 data={dataBlog} className="inner-news-section" /> */}

      <Footer />
      <Gotop />
    </>
  );
}

export default Jobsingle_v1;
